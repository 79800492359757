<script setup lang="ts">
import { computed } from 'vue';
import GenericModal from '../modals/GenericModal.vue';
import ButtonComponent from '@/components/common/ButtonComponent.vue';
import useSocialStore from '@/stores/social';
import { MinifiedSocialUser } from '@/services';

interface Props {
  modelValue: boolean;
}
const socialStore = useSocialStore();
const props = defineProps<Props>();

const emit = defineEmits<{
  'update:modelValue': [value: boolean];
}>();

const show = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

async function handleUnblockClick(user: MinifiedSocialUser) {
  await socialStore.unblock(user);

  if (socialStore._socialUser?.blockedUsers?.length === 0) {
    show.value = false;
  }
}
</script>

<template>
  <GenericModal
    v-model="show"
    size="md"
    bg-class="bg-backgrounds-darkgrey"
    title="Blocked users"
  >
    <div class="w-full gap-3">
      <div class="max-h-[450px] overflow-y-auto overflow-x-hidden pt-7">
        <template
          v-for="user in socialStore._socialUser?.blockedUsers"
          :key="user._id"
        >
          <div class="mb-4 flex items-center">
            <div class="relative">
              <div
                class="flex size-[50px] items-center justify-center overflow-hidden rounded-full border-2 border-primary"
              >
                <img
                  class="w-full rounded-full"
                  :src="
                    user.userImage ??
                    `https://ui-avatars.com/api/?name=${user.firstName}+${user.lastName}`
                  "
                />
              </div>
            </div>
            <div class="ml-3">
              <p class="text-lg">@{{ user.username }}</p>
            </div>
            <div
              class="tooltip ml-auto mr-4 flex items-center gap-3"
              data-tip="Unblock"
            >
              <ButtonComponent
                class=""
                variant="primary"
                rounded
                @click="handleUnblockClick(user)"
              >
                <IconifyIcon width="1.6em" icon="lets-icons:lock" />
              </ButtonComponent>
            </div>
          </div>
        </template>
      </div>
    </div>
    <template #footer>
      <div class="mt-5 flex w-full flex-col items-end">
        <ButtonComponent type="button" @click="show = false">
          Close
        </ButtonComponent>
      </div>
    </template>
  </GenericModal>
</template>

<style scoped></style>

import axios from 'axios';

import type { RegistrationData } from '@/stores/auth';

import type { AuthRequest, UserPayload } from './auth.service.types';

const backendUrl = import.meta.env.VITE_AUTH_URL;

const commonHeaders = {
  'Content-Type': 'application/json',
};

const authApi = axios.create({
  baseURL: backendUrl,
  withCredentials: true,
  headers: { ...commonHeaders },
});

interface AuthUrlResponse {
  google: string;
  microsoft: string;
  upm: string;
}

export const authService = {
  login(dataRq: AuthRequest) {
    return authApi.post<UserPayload>('/login', dataRq);
  },

  async logout() {
    await authApi.post('/logout');
  },

  async sendToken(newUserData: RegistrationData) {
    return await authApi.post('/send-token', newUserData);
  },

  async refreshCookie() {
    return await authApi.post<UserPayload>('/refresh-cookie');
  },
  async getAuthUrl() {
    return await authApi.get<AuthUrlResponse>(`/public/url?type=all`);
  },
  sendCodeToRestorePassword(email: string) {
    return authApi.post(`/update-password-token`, { email: email });
  },
  validateCodeToRestorePassword(email: string, tokenCode: string) {
    return authApi.post(`/check-token`, { email: email, tokenCode: tokenCode });
  },
  async connectToRoom(room: string) {
    return await authApi.post<{ message: string }>('/room/connect', {
      productVirtualSlug: room,
    });
  },

  async refreshConnection(room: string) {
    return await authApi.post<{ message: string }>('/room/refresh', {
      productVirtualSlug: room,
    });
  },

  resetPassword(
    email: string,
    tokenCode: string,
    password: string,
    confirmPassword: string,
  ) {
    return authApi.post(`/update-password`, {
      email: email,
      tokenCode: tokenCode,
      password: password,
      confirmPassword: confirmPassword,
    });
  },
};
